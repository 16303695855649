// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
// |--------------------------------------------------------------------------
// | Banner Accueil
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner la
// | bannière de l'accueil.
// |
// | IMPORTS _____
// | -> jQuery
// |
// | OPTIONS _____
// | container => Le bloc de la bannière d'accueil.
// | slider => Le sélecteur du 'slider'.
// | slide => Le sélecteur des 'slide'.
// | navigation => Le sélecteur du container contenant 'current', 'total' 
// |               et la navigation.
// | prev => Le sélecteur de 'prev' (pour aller à la slide précédente).
// | next => Le sélecteur de 'next' (pour aller à la slide suivante).
// | close => Le sélecteur du bouton 'close' (pour masquer la bannière).
// | showClass => La classe permettant d'afficher le 'container' et les
// |              'navigation'.
// | show0 => Permet d'afficher un 0 devant 'current' et 'total' en bas de
// |          10.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
// ---------------------------------------------------------------------------


export function swiperHomeBanner(options = {}) {

  // Déclaration des propriétés par défaut
  options.container === undefined ? options.container = '[data-swiper="home-banner-container"]' : options.container
  options.slider === undefined ? options.slider = '[data-swiper="home-banner"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="home-banner-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="home-banner-navigation"]' : options.navigation
  options.next === undefined ? options.next = '[data-swiper="home-banner-next"]' : options.next

  // Déclarations des variables
  let container = document.querySelector(options.container)

  // Si la page contient le container home-banner
  if (typeof (container) != 'undefined' && container != null) {

    // Si il y a plus qu'une slide
    if (document.querySelectorAll(options.slide).length > 1) {

      // Création du slider
      // window.addEventListener('load', () => {
        window["swiper-home-banner"] = new Swiper(options.slider, {
          threshold: 10,
          loop: true,
          slidesPerView: 1,
          speed: 500,
          autoplay: {
            delay: 7000,
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true
          },
          navigation: {
            nextEl: options.next
          },
          runCallbacksOnInit: true, 
          on: {
            init: function(sw){
              let fraction = document.querySelector('[data-swiper="home-banner-pagination-fraction"]')
              let index
              let length

              if((sw.realIndex + 1) < 10) {
                index = '0' + (sw.realIndex + 1)
              } else {
                index = sw.realIndex + 1
              }

              if((sw.slides.length - (sw.loopedSlides*2)) < 10) {
                length = '0' + (sw.slides.length - (sw.loopedSlides*2))
              } else {
                length = sw.slides.length - (sw.loopedSlides*2)
              }

              fraction.innerHTML = '<span>' + index + '</span>' + '/' + length
            }, //runs callback in initialization
            slideChange: function(sw){
              let fraction = document.querySelector('[data-swiper="home-banner-pagination-fraction"]')
              let index
              let length

              if((sw.realIndex + 1) < 10) {
                index = '0' + (sw.realIndex + 1)
              } else {
                index = sw.realIndex + 1
              }

              if((sw.slides.length - (sw.loopedSlides*2)) < 10) {
                length = '0' + (sw.slides.length - (sw.loopedSlides*2))
              } else {
                length = sw.slides.length - (sw.loopedSlides*2)
              }

              fraction.innerHTML = '<span>' + index + '</span>' + '/' + length
            } //runs callback in slide change end
          },
        })
      // }, false)
    }
  }
}