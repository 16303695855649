
import $ from 'jquery'
import Overlay from '../vendor/overlay.js'
import { Accordions } from '../classes/accordions'
import { OBSERVER } from './../main.js'
import { copyTextToClipboard } from './functions.js'

// Fonction gérant l'overlay Share
export function overlayShare() {
  let siteName = window.config.site_name

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  }).init()

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  $('.js-copy-share').on('click', function() {
    let link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    return false
  })

  $('.js-share').on('click', function(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}

// Fonction gérant l'overlay Dropdown
export const overlayDropdown = () => {
  new Overlay({
    name: 'dropdown',
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-dropdown"]',
        close: '[data-js="close-overlay-dropdown"], a[href]',
        switch: '.js-share, [data-js="toggle-overlay-menu"], [data-js="toggle-overlay-fast-links"], [data-js="toggle-search"], [data-js="toggle-calendar"]'
      }
    },
    options: { 
      speed: 1000,
      goToSelector: 'html, body'
    }
  }).init()
}

// Fonction gérant l'overlay Menu
export const overlayMenu = () => {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-menu"]',
        close: '[data-js="close-overlay-menu"], a[href]',
        switch: '[data-js="trigger-overlay-dropdown"], .js-share, [data-js="toggle-overlay-fast-links"], [data-js="toggle-search"], [data-js="toggle-calendar"]'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body', 
    }
  })
  
  menu.init()

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
  })

  const secondLevel = new Accordions({
    name: 'SecondLevel',
    containerClass: 'js-accordions-second-level',
    accordionClass: 'js-accordion-second-level',
    toggleButtonClass: 'js-accordion-toggle-second-level',
    contentClass: 'js-accordion-content-second-level',
    openedClass: 'js-accordion-opened-second-level',
  })

  const onClose = () => {
    mainLevel.closeAll()
    secondLevel.closeAll()
  }

  const onCloseMainLevel = () => {
    secondLevel.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseAccordionsMainLevel',
    function: onCloseMainLevel
  })

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseAllAccordionsMainLevel',
    function: onCloseMainLevel
  })

  OBSERVER.on('overlayMenu')
}

// Fonction gérant l'overlay de recherche
export const overlaySearch = () => {
  new Overlay({
    name: 'search',
    events: {
      openComplete: true
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-search"]',
        close: '[data-js="close-search"]',
        switch: '[data-js="trigger-overlay-dropdown"], [data-js="toggle-overlay-menu"], [data-js="toggle-overlay-fast-links"], [data-js="toggle-calendar"], .js-share'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body', 
      closeOnResize: false,
    }
  }).init()
  
  const onFocus = () => { document.getElementById('inputFocus').focus() }
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onFocus })
  OBSERVER.on('overlaySearch')
}

// Fonction gérant l'overlay du popup
export function overlayPopup() {
  let popup = new Overlay({
    name: 'popup',
    create: {
      background: false
    },
    timeout: {
      delay: 300,
    },
    click: {
      buttons: {
        close: '.js-close-overlay-popup'
      }
    },
    options: {
      speed: 800,
    }
  })

  popup.init()
}

// Fonction gérant l'overlay Fast-links
export const overlayFastlinks = () => {
  new Overlay({
    name: 'fast-links',
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-fast-links"]',
        close: 'a[href]',
        switch: '[data-js="trigger-overlay-dropdown"], [data-js="toggle-overlay-menu"], .js-share, [data-js="toggle-search"], [data-js="toggle-calendar"]'
      }
    },
    options: { 
      speed: 1000,
    }
  }).init()
}

// Fonction gérant l'overlay de calendrier
export const overlayCalendar = () => {
  let calendar = new Overlay({
    name: 'calendar',
    events: {
      close: true
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-calendar"]',
        close: '[data-js="close-calendar"]',
        switch: '[data-js="trigger-overlay-dropdown"], [data-js="toggle-overlay-menu"], [data-js="toggle-overlay-fast-links"], .js-share'
      }
    },
    options: {
      speed: 800,
      closeOnResize: false,
    }
  })
  
  calendar.init()
}