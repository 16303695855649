// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
// |--------------------------------------------------------------------------
// | Actualités Accueil
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner le swiper des
// | actualités similaires.
// |
// | IMPORTS _____
// | -> jQuery
// |
// | OPTIONS _____
// | container => Le bloc des actualités similaires.
// | slider => Le sélecteur du 'slider'.
// | slide => Le sélecteur des 'slide'.
// | navigation => Le sélecteur du container contenant 'current', 'total' 
// |               et la navigation.
// | prev => Le sélecteur de 'prev' (pour aller à la slide précédente).
// | next => Le sélecteur de 'next' (pour aller à la slide suivante).
// | close => Le sélecteur du bouton 'close' (pour masquer la bannière).
// | showClass => La classe permettant d'afficher le 'container' et les
// |              'navigation'.
// | show0 => Permet d'afficher un 0 devant 'current' et 'total' en bas de
// |          10.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
// ---------------------------------------------------------------------------


export function swiperSimilarNews(options = {}) {

  // Déclaration des propriétés par défaut
  options.container === undefined ? options.container = '[data-swiper="similar-news-container"]' : options.container
  options.slider === undefined ? options.slider = '[data-swiper="similar-news"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="similar-news-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="similar-news-navigation"]' : options.navigation
  options.prev === undefined ? options.prev = '[data-swiper="similar-news-prev"]' : options.prev
  options.next === undefined ? options.next = '[data-swiper="similar-news-next"]' : options.next

  // Déclarations des variables
  let container = document.querySelector(options.container)

  // Si la page contient le container home-news
  if (typeof (container) != 'undefined' && container != null) {

    // Si il y a plus qu'une slide
    if (document.querySelectorAll(options.slide).length > 1) {

      // Création du slider
      // window.addEventListener('load', () => {
        window["swiper-similar-news"] = new Swiper(options.slider, {
          threshold: 10,
          loop: true,
          speed: 500,
          spaceBetween: 20,
          slidesPerView: "auto",
          autoplay: {
            delay: 7000,
          },
          navigation: {
            nextEl: options.next
          },
          pagination: {
            el: '.similar-news__swiper-pagination-progressbar',
            type: 'progressbar',
          },
          runCallbacksOnInit: true, 
          on: {
            init: function(sw2){
              let fraction = document.querySelector('[data-swiper="similar-news-pagination-fraction"]')
              let index
              let length

              if((sw2.realIndex + 1) < 10) {
                index = '0' + (sw2.realIndex + 1)
              } else {
                index = sw2.realIndex + 1
              }

              if((sw2.slides.length - (sw2.loopedSlides*2)) < 10) {
                length = '0' + (sw2.slides.length - (sw2.loopedSlides*2))
              } else {
                length = sw2.slides.length - (sw2.loopedSlides*2)
              }

              fraction.innerHTML = '<span>' + index + '</span>' + '/' + length
            }, //runs callback in initialization
            slideChange: function(sw2){
              let fraction = document.querySelector('[data-swiper="similar-news-pagination-fraction"]')
              let index
              let length

              if((sw2.realIndex + 1) < 10) {
                index = '0' + (sw2.realIndex + 1)
              } else {
                index = sw2.realIndex + 1
              }

              if((sw2.slides.length - (sw2.loopedSlides*2)) < 10) {
                length = '0' + (sw2.slides.length - (sw2.loopedSlides*2))
              } else {
                length = sw2.slides.length - (sw2.loopedSlides*2)
              }

              fraction.innerHTML = '<span>' + index + '</span>' + '/' + length
            } //runs callback in slide change end
          },
          breakpoints: {
            600: {
              spaceBetween: 70,
            },
            500: {
              spaceBetween: 40,
            },
          }
        })
      // }, false)
    }
  }
}