import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, calendar, clickToScrollToBlock, getPageTitleHeight, hideFastLinks, homeEventSectionImageHeight, ogContent, revealFastLinks, slugifyProvider } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, clearInput, tailSelect, inputsAndTextareaLabel } from './functions/form.js'
import { overlayCalendar, overlayDropdown, overlayFastlinks, overlayMenu, overlayPopup, overlaySearch, overlayShare  } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formContact, formExample, formJobs, formSearch, formSuccess } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { hundredVH, removeHoverOnMobile, sizeTitle } from './functions/helper.js'
import { swiperAlert } from './functions/alert.js'
import { swiperHomeBanner } from './functions/home-banner.js'
import { swiperHomeNews } from './functions/home-news.js'
import { swiperHomeEvents } from './functions/home-events.js'
import { swiperSimilarNews } from './functions/similar-news.js'
import { TyperSetup } from './vendor/typer.js'
import { backToTop } from './functions/back-to-top-and-links.js'

window.barba = barba
window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.slugifyProvider = slugifyProvider

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    hundredVH() 
    sizeTitle({
      caract01: "30",
      caract02: "40",
      caract03: "60",
    })
    TyperSetup()
    overlayShare()
    overlayDropdown()
    overlayMenu()
    overlaySearch()
    backendKeyBind()
    SCROLLFIRE.init()
    removeHoverOnMobile()
    inputsAndTextareaLabel()
    clearInput()
    getPageTitleHeight()
    window['lazyload'] = new LazyLoad()
    backToTop()
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.after(() => { 
    gtag('event', 'page_view', { 'page_path': location.pathname + location.search }) 
  })
  
  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
    window['lazyload'].destroy()
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          new Accordions()
          masksPackage()
          dynamicPackage()
          swiperSimilarNews()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

      {
        namespace: 'homeView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          overlayFastlinks()
          revealFastLinks()
          swiperHomeBanner()
          swiperHomeNews()
          swiperHomeEvents()
          homeEventSectionImageHeight()
          hideFastLinks()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-news"]', scrollTo: '[data-js="news"]' })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

      {
        namespace: 'eventsListView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          calendar()
          overlayCalendar()
          tailSelect()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

      {
        namespace: 'eventsDetailView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

      {
        namespace: 'newsListView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          tailSelect()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

      {
        namespace: 'newsDetailView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          swiperSimilarNews()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

      {
        namespace: 'searchResultsView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          tailSelect()
          formSearch('#form-search', '#form-search-submit')
        },
        afterLeave () {}
      },

      {
        namespace: 'contactView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          new Accordions()
          formContact()
          masksPackage()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-coords"]', scrollTo: '[data-js="coords"]' })
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

      {
        namespace: 'jobsListView',
        beforeEnter() {
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' });
          swiperAlert()
          overlayPopup()
          formJobs()
          formsPackage()
          masksPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

      {
        namespace: 'jobsDetailView',
        beforeEnter() {
          swiperAlert()
          overlayPopup()
          formJobs()
          formsPackage()
          masksPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },

    ]
  })
}
