// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
// |--------------------------------------------------------------------------
// | Banner Alertes
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner la
// | bannière des alertes.
// |
// | IMPORTS _____
// | -> jQuery
// | -> Observer
// |
// | OPTIONS _____
// | container => Le bloc de la bannière d'alerte.
// | slider => Le sélecteur du 'slider'.
// | slide => Le sélecteur des 'slide'.
// | navigation => Le sélecteur du container contenant 'current', 'total' 
// |               et la navigation.
// | prev => Le sélecteur de 'prev' (pour aller à la slide précédente).
// | next => Le sélecteur de 'next' (pour aller à la slide suivante).
// | close => Le sélecteur du bouton 'close' (pour masquer la bannière).
// | showClass => La classe permettant d'afficher le 'container' et les
// |              'navigation'.
// | show0 => Permet d'afficher un 0 devant 'current' et 'total' en bas de
// |          10.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
import { OBSERVER } from './../main.js'
// ---------------------------------------------------------------------------


export function swiperAlert(options = {}) {

  // Déclaration des propriétés par défaut
  options.container === undefined ? options.container = '[data-swiper="alert-container"]' : options.container
  options.slider === undefined ? options.slider = '[data-swiper="alert"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="alert-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="alert-navigation"]' : options.navigation
  options.prev === undefined ? options.prev = '[data-swiper="alert-prev"]' : options.prev
  options.next === undefined ? options.next = '[data-swiper="alert-next"]' : options.next
  options.close === undefined ? options.close = '[data-swiper="alert-close"]' : options.close 

  options.containerMobile === undefined ? options.containerMobile = '[data-swiper="alert-mobile-container"]' : options.containerMobile
  options.sliderMobile === undefined ? options.sliderMobile = '[data-swiper="alert-mobile"]' : options.sliderMobile
  options.slideMobile === undefined ? options.slideMobile = '[data-swiper="alert-mobile-slide"]' : options.slideMobile
  options.navigationMobile === undefined ? options.navigationMobile = '[data-swiper="alert-mobile-navigation"]' : options.navigationMobile
  options.prevMobile === undefined ? options.prevMobile = '[data-swiper="alert-mobile-prev"]' : options.prevMobile
  options.nextMobile === undefined ? options.nextMobile = '[data-swiper="alert-mobile-next"]' : options.nextMobile
  options.closeMobile === undefined ? options.closeMobile = '[data-swiper="alert-mobile-close"]' : options.closeMobile
  options.closeMobile === undefined ? options.closeMobile = '[data-swiper="alert-mobile-close"]' : options.closeMobile

  // Déclarations des variables
  let container = document.querySelector(options.container)
  let containerMobile = document.querySelector(options.containerMobile)

  // Déclarations des fonctions
  const closeAlert = () => container.classList.add('close')
  const closeAlertMobile = () => document.documentElement.style.setProperty('--alert-height', '0px')

  // Ajout de l événement pour masquer la bannière d'alertes 
  OBSERVER.add({
    name: 'alert',
    event: 'click',
    function: closeAlert,
    target: options.close
  })
  OBSERVER.add({
    name: 'alert',
    event: 'click',
    function: closeAlertMobile,
    target: options.closeMobile
  })
  OBSERVER.on('alert')

  // Si la page contient le container alert
  if (typeof (container) != 'undefined' && container != null) {

    // Si il y a plus qu'une slide
    if (document.querySelectorAll(options.slide).length > 1) {

      // Création du slider
      window.addEventListener('load', () => {
        window["swiper-alert"] = new Swiper(options.slider, {
          threshold: 10,
          loop: true,
          slidesPerView: 1,
          spaceBetween: 25,
          speed: 500,
          autoHeight: true,
          autoplay: {
            delay: 7000,
          },
          pagination: {
            el: '[data-swiper="alert-pagination"]',
            type: 'fraction',
          },
          navigation: {
            prevEl: options.prev,
            nextEl: options.next
          },
          runCallbacksOnInit: true, 
          on: {
            init: function(sw){
              let fraction = document.querySelector('[data-swiper="alert-pagination-fraction"]')
              fraction.innerHTML = (sw.realIndex + 1) + '/' + (sw.slides.length - (sw.loopedSlides*2))
            }, //runs callback in initialization
            slideChange: function(sw){
              let fraction = document.querySelector('[data-swiper="alert-pagination-fraction"]')
              fraction.innerHTML = (sw.realIndex + 1) + '/' + (sw.slides.length - (sw.loopedSlides*2))
            } //runs callback in slide change end
          }
        })
      }, false)
    }
  }

  // Si la page contient le container alertMobile
  if (typeof (containerMobile) != 'undefined' && containerMobile != null) {

    // Si il y a plus qu'une slide
    if (document.querySelectorAll(options.slideMobile).length > 1) {

      // Création du slider
      window.addEventListener('load', () => {
        window["swiper-alert-mobile"] = new Swiper(options.sliderMobile, {
          threshold: 10,
          loop: true,
          slidesPerView: 1,
          spaceBetween: 25,
          speed: 500,
          autoplay: {
            delay: 7000,
          },
          pagination: {
            el: '[data-swiper="alert-mobile-pagination"]',
            type: 'fraction',
          },
          navigation: {
            prevEl: options.prevMobile,
            nextEl: options.nextMobile
          },
          runCallbacksOnInit: true, 
          on: {
            init: function(swm){
              let fraction = document.querySelector('[data-swiper="alert-mobile-pagination-fraction"]')
              fraction.innerHTML = (swm.realIndex + 1) + '/' + (swm.slides.length - (swm.loopedSlides*2))
            }, //runs callback in initialization
            slideChange: function(swm){
              let fraction = document.querySelector('[data-swiper="alert-mobile-pagination-fraction"]')
              fraction.innerHTML = (swm.realIndex + 1) + '/' + (swm.slides.length - (swm.loopedSlides*2))
            } //runs callback in slide change end
          }
        })
      }, false)
    }
  } else {
    document.documentElement.style.setProperty('--alert-height', '0px')
  }
}