import $ from 'jquery'
import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset, isVisible } from './helper.js'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })
  
  return animation.finished
}


// ---------------------------------------------------------------------------
// Fonction permettant d'activer le calendrier
export const calendar = (root = document) => {
  
  if (!root.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = root.querySelector('#calendarOptions').dataset.list
  let currentDate = root.querySelector('#calendarOptions').dataset.date
  console.log(eventsDatesList)

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')
  
  $(root).find('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) {
        data.then(function(data){ barba.go('/evenements/' + data['date'])})
        let body = root.querySelector('html')
        body.classList.remove('calendar--open')
      },
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $(root).find('.calendar').datepicker('update', currentDate)

  addZero()
}

function addZero() {
  getDate()

  const targetNode = document.querySelector('.datepicker-days .table-condensed tbody')
  const config = { attributes: true, childList: true, subtree: true, characterData: true }
  const callback = function() {
    getDate()

    observer.disconnect()
    observer.observe(targetNode, config)
  }

  const observer = new MutationObserver(callback)
  observer.observe(targetNode, config)

  function getDate() {
    let dates = document.querySelectorAll('.day')
    let date

    for(date of dates) {
      
      if( date.innerHTML < 10) {
        let newDate = "0" + date.innerHTML
        date.innerHTML = newDate
      }
    }
  }
}


// Copier du texte
export function copyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// Ajoute les metas pour le contenu og
export function ogContent(data) {
  let ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
  let ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
  let ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
  let ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
  document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
  document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
  document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
  document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
}


// Permet d'aller au backend
export function backendKeyBind() {
  $('body').keydown(function (e) {
    let keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
}


// Permet d'afficher/cacher les liens rapides en desktop
export function revealFastLinks() {
  let fastLinks = document.querySelector('[data-js="fast-links"]')
  let toggleExpansion = document.querySelector('[data-js="toggle-fast-links"]')

  function reveal() {
    let alreadyExpend = document.querySelector('.expansion')
    let buttonTextOpen = document.querySelector('.js-button-text-open')
    let buttonTextClose = document.querySelector('.js-button-text-close')

    if (typeof (alreadyExpend) != 'undefined' && alreadyExpend != null) {
      fastLinks.classList.remove('expansion')
      buttonTextOpen.classList.remove('hide')
      buttonTextClose.classList.add('hide')
    } else {
      fastLinks.classList.add('expansion')
      buttonTextOpen.classList.add('hide')
      buttonTextClose.classList.remove('hide')
    }
  }
  
  OBSERVER.add({
    name: 'reveal',
    event: 'click',
    target: toggleExpansion,
    function: reveal
  })

  OBSERVER.on('reveal')
}

// Va chercher la hauteur de l'image dans la section des événements de l'accueil
export function homeEventSectionImageHeight() {
  if(document.querySelector('.home-events-section__picture-container') == null) return
  
  const setHomeEventSectionImageHeightVariable = () => {
    document.documentElement.style.setProperty('--home-events-section-image-height', `${document.querySelector('.home-events-section__picture-container').offsetHeight}px`)
  }

  setHomeEventSectionImageHeightVariable()

  OBSERVER.add({
    name: 'setHomeEventSectionImageHeightVariable',
    event: 'resize',
    function: setHomeEventSectionImageHeightVariable
  })

  OBSERVER.on('setHomeEventSectionImageHeightVariable')
}

// Faire disparaître le bouton des liens rapides si le footer est visible (en mobile)
export function hideFastLinks() {
  let footer = document.querySelector('.footer')
  let fastLinksButton = document.querySelector('.fast-links-button')

  if (typeof (fastLinksButton) != 'undefined' && fastLinksButton != null) {
    OBSERVER.add({ name: 'hideOrNot', event: 'scroll', function: onScroll, target: 'document' })
    OBSERVER.on('hideOrNot')
  
    onScroll()
  
    function onScroll() {
      if (isVisible(footer)) {
        fastLinksButton.classList.add('hidden')
      }
      else {
        fastLinksButton.classList.remove('hidden')
      }
    }
  }
}

// Va chercher la hauteur du page-title pour la donner à l'image de la side-section
export function getPageTitleHeight() {
  if(document.querySelector('.layout__side-section--with-image') == null) return

  const setPageTitleHeightVariable = () => {
    document.documentElement.style.setProperty('--page-title-height', `${document.querySelector('.page-title').offsetHeight}px`)
  }

  setPageTitleHeightVariable()

  OBSERVER.add({
    name: 'setPageTitleHeightVariable',
    event: 'resize',
    function: setPageTitleHeightVariable
  })

  OBSERVER.on('setPageTitleHeightVariable')
}


//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.replace(/ /g,"-")
  result = result.toLowerCase()

  return result
}