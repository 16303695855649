// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
// |--------------------------------------------------------------------------
// | Événements Accueil
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner le swiper des
// | événements de l'accueil.
// |
// | IMPORTS _____
// | -> jQuery
// |
// | OPTIONS _____
// | container => Le bloc des événements de l'accueil.
// | slider => Le sélecteur du 'slider'.
// | slide => Le sélecteur des 'slide'.
// | navigation => Le sélecteur du container contenant 'current', 'total' 
// |               et la navigation.
// | prev => Le sélecteur de 'prev' (pour aller à la slide précédente).
// | next => Le sélecteur de 'next' (pour aller à la slide suivante).
// | close => Le sélecteur du bouton 'close' (pour masquer la bannière).
// | showClass => La classe permettant d'afficher le 'container' et les
// |              'navigation'.
// | show0 => Permet d'afficher un 0 devant 'current' et 'total' en bas de
// |          10.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
// ---------------------------------------------------------------------------


export function swiperHomeEvents(options = {}) {

  // Déclaration des propriétés par défaut
  options.container === undefined ? options.container = '[data-swiper="home-events-section-container"]' : options.container
  options.slider === undefined ? options.slider = '[data-swiper="home-events-section"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="home-events-section-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="home-events-section-navigation"]' : options.navigation
  options.next === undefined ? options.next = '[data-swiper="home-events-section-next"]' : options.next

  // Déclarations des variables
  let container = document.querySelector(options.container)

  // Si la page contient le container home-events
  if (typeof (container) != 'undefined' && container != null) {

    // Si il y a plus qu'une slide
    if (document.querySelectorAll(options.slide).length > 1) {

      // Création du slider
      // window.addEventListener('load', () => {
        window["swiper-home-events-section"] = new Swiper(options.slider, {
          threshold: 10,
          loop: true,
          speed: 500,
          slidesPerView: 1,
          autoplay: {
            delay: 7000,
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true
          },
          navigation: {
            nextEl: options.next
          },
        })
      // }, false)
    }
  }
}